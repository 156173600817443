//Data for each item
export interface EventInfo {
  event: EventItem[];
  user: UserIBO[];
  tickets: Ticket[];
}

//Item
export interface EventItem {
  eventId: number;
  title: string;
  subTitle: string;
  isAvailable: boolean;
  speaker: Speaker;
  startsOn: string | null | undefined;
  endsOn: string | null | undefined;
  isVirtual: boolean;
  isInPerson: boolean;
  location: Location;
  notes: Notes[];

  //added
  users?: UserIBO[];
  ticket?: Ticket[];
  // priceStr?: string;
  // description?: string;
  // isLocked: boolean;
  // pinNotes: PinNotes[];
}

export interface Location {
  line1: string;
  line2: string;
  city: string;
  zip: string;
  venueName: string;
}

export interface Speaker {
  name: string;
  src: string;
}

export interface LocalFunctionsData {
  eventTemplateId: number;
  templateName: string;
  templateType: string;
  icon: string;
  headerText: string;
  description: string | null;
  promoStartsOn: string;
  promoEndsOn: string;
  primaryButtonAction: PrimaryButtonAction;
  // infoButtonReference: number;
  primaryButtonText: string;
  secondaryButtonText: string | null;
  secondaryButtonAction: SecondaryButtonAction | null;
  items: EventItem[];

  user: UserIBO[];
}

export interface Notes {
  id: number;
  note: string;
}

interface PrimaryButtonAction {
  component: string;
  data: {
    contentId: string;
  };
}

interface SecondaryButtonAction {
  // Not sure what data in here
}

// export interface PinNotes {
//   id: number;
//   pinNote: string;
// }

// export interface IBO {
//   image: string;
//   firstName: string;
//   ticketStatus: TicketStatus;
// }

export interface Ticket {
  ticketId: number;
  title: string;
  currencyCode: string;
  priceStr: string;
  status: TicketStatus;
}

export interface UserIBO {
  Name: string;
  UserId: number;
  src: string;
  tickets: Ticket[];
}

export enum TicketStatus {
  New = 1,
  Selected = 2,
  AddToCart = 3,
  Purchased = 4,
  Included = 5,
  Unavailable = 6,
}

// export interface User {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }
