import React from "react";
import { FC } from "react";
import EventIcon from "components/EventIcon";
import TicketPurchaseItem from "components/TicketPurchaseItem";
import styles from "./LocalFunctionEventTicketItem.module.css";
import {
  EventInfo,
  EventItem,
  Tickets,
  TempUserIBO,
  EventSessions,
} from "../../../stores/interfaces.types";
import LocalFunctionEventIcon from "components/LocalFunctionComponents/LocalFunctionEventIcon";
import LocalFunctionTicketPurchaseItem from "components/LocalFunctionComponents/LocalFunctionTicketPurchaseItem";

interface IProps {
  // event: EventInfo;
  // event: EventItem[];
  // event: Tickets;
  session: EventSessions;
  users: TempUserIBO[];

  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const LocalFunctionEventTicketItem: FC<IProps> = ({
  // event,
  session,
  users,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  // const currentEvent = event?.event[0];
  return (
    <div className={styles.section}>
      <div className={styles.left}>
        {/* <LocalFunctionEventIcon events={event} /> */}
        <LocalFunctionEventIcon events={session} />
      </div>
      <div className={styles.right}>
        {/* {event?.user?.map((user, index) => ( */}
        {users?.map((user, index) => (
          <LocalFunctionTicketPurchaseItem
            key={index}
            user={user}
            onClickAdd={() => onClickAdd(user)}
            onClickPurchased={() => onClickPurchased(user)}
            onClickCancel={() => onClickCancel(user)}
          />
        ))}
      </div>
    </div>
  );
};
