import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "utils";
import styles from "./MajorFunctions.module.css";
import { MajorFunctionsList } from "./MajorFunctionsList/MajorFunctionsList";
import { EmergencyContactModal } from "components/EmergencyContactModal/EmergencyContactModal";
// import { EFunctionStatus, IFunction, IFunctionIBO } from "../../models";
import {
  ETicketStatus,
  IMajorFunctions,
  ITempUserIBO,
} from "../../stores/majorInterface";

import { mockMajorData } from "./mockMajorData";
import { useCart } from "../../stores/CartContext";
import Footer from "components/Footer";
import Button from "components/Button";
import CartPopup from "components/CartPopup";
import { useMajorFunctions } from "./useMajorFunctions";
import Loader from "components/Loader";

export const MajorFunctions = () => {
  const history = useHistory();
  const { addToCart } = useCart();
  const { data: majorFuncData, isLoading: majorFunctionLoading } =
    useMajorFunctions();

  const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
  const [isFooterOpen, setIsFooterOpen] = useState(false);

  // const [functions, setFunctions] = useState([]);
  // const [selectedFunction, setSelectedFunction] = useState({} as IFunction);
  const [majorEvents, setMajorEvents] = useState<IMajorFunctions>(
    {} as IMajorFunctions
  );

  const [functions, setFunctions] = useState<IMajorFunctions[]>([]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEventIndex, setCurrentEventIndex] = useState<number>(0);

  // console.log("INSIDE MAJOR,", majorFuncData);

  // useEffect(() => {
  //   // const majorFuncData = data;
  //   // setFunctions(functions);

  //   if (majorFuncData?.length > 0) setMajorEvents(majorFuncData[0]);
  //   else setMajorEvents({} as IFunction);
  // }, []);

  // useEffect(() => {
  //   if (majorFuncData) {
  //     // setFunctions(data);

  //     if (majorFuncData.length > 0) {
  //       setMajorEvents(majorFuncData[0]);
  //     } else {
  //       setMajorEvents({} as IMajorFunctions);
  //     }
  //   }
  // }, [majorFuncData]);

  useEffect(() => {
    if (majorFuncData && majorFuncData.length > 0) {
      setFunctions(majorFuncData);
      setMajorEvents(majorFuncData[0]);
      setCurrentEventIndex(0);
    } else {
      setFunctions([]);
      setMajorEvents({} as IMajorFunctions);
      setCurrentEventIndex(-1);
    }
  }, [majorFuncData]);

  // console.log("INSIDE functions,", functions);
  // console.log("INSIDE majorEvents,", majorEvents);

  useEffect(() => {
    if (isAddToCartOpen && selectedItems.length > 0) {
      selectedItems.forEach((item) => addToCart(item));
      setSelectedItems([]);
    }
  }, [selectedItems, isAddToCartOpen, addToCart]);

  //Handles the arrow button which changes the events
  const onChangeSelectedFunction = (isForward: boolean) => {
    let currentIndex = majorFuncData.findIndex(
      (event) => event.eventTemplateId === majorEvents.eventTemplateId
    );
    let selectedIndex = -1;

    if (isForward) {
      if (currentIndex < functions.length - 1) {
        selectedIndex = currentIndex + 1;
      }
    } else {
      if (currentIndex > 0) {
        selectedIndex = currentIndex - 1;
      }
    }

    // setMajorEvents(majorFuncData[selectedIndex]);
    if (selectedIndex !== currentIndex) {
      setMajorEvents(functions[selectedIndex]);
      setCurrentEventIndex(selectedIndex);
    }
  };

  //CART
  // let purchasing = majorEvents?.items?.some((a) =>
  //   a.ibos?.some((a) => a.ticketStatus === ETicketStatus.Selected)
  // );
  // let events = null;
  // let tickets = null;
  // let total = 0;

  //CART
  // if (purchasing) {
  //   events = majorEvents?.items?.filter((a) =>
  //     a.ibos?.some((a) => a.ticketStatus === ETicketStatus.Selected)
  //   );
  //   tickets = events.flatMap((a) =>
  //     a.ibos?.filter((a) => a.ticketStatus === ETicketStatus.Selected)
  //   );
  //   total = events?.length * tickets?.length * majorEvents.price;
  // }

  //Function to add ibo

  const onClickAdd = () => {
    //CART

    // const onClickAdd = (ibo, event) => {
    // ibo.ticketStatus = ETicketStatus.Selected;
    // const updatedFunctions = functions.map((f) => {
    //   if (f.id === majorEvents.id) {
    //     return {
    //       ...f,
    //       events: f.events?.map((e) => {
    //         if (e?.eventId === event?.eventId) {
    //           return {
    //             ...e,
    //             ibos: e.ibos.map((i) =>
    //               i.firstName === ibo.firstName
    //                 ? { ...ibo, ticketStatus: ETicketStatus.Selected }
    //                 : i
    //             ),
    //           };
    //         }
    //         return e;
    //       }),
    //     };
    //   }
    //   return f;
    // });

    //CART
    // setFunctions(updatedFunctions);

    //CART
    // //Add items to cart
    // const newCartItem = {
    //   eventId: event.eventId,
    //   eventName: majorEvents.name,
    //   iboName: ibo.firstName,
    //   iboImage: ibo.image,
    //   price: majorEvents.price,
    //   currencyUnit: majorEvents.currencyUnit,
    //   currency: majorEvents.currency,
    // };

    //CART
    // setSelectedItems((prev) => [...prev, newCartItem]);
    setIsFooterOpen(true);
  };

  const onClickCancel = () => {
    // CART
    // const onClickCancel = (ibo: ITempUserIBO) => {
    // ibo.ticketStatus = ETicketStatus.New;
    // setFunctions((prev) => [...prev]);
    // // Remove from the temporary list
    // setSelectedItems((prev) =>
    //   prev.filter((item) => item.iboName !== ibo.firstName)
    // );
  };

  const onRedirectToCart = () => {
    setIsAddToCartOpen(true);
    setTimeout(() => {
      setIsAddToCartOpen(false);
      history.push(PATHS.CART);
    }, 700);
    // setSelectedItems([]);
    // setIsFooterOpen(false);
  };

  const onClickBuyNow = () => {
    setShowModal(true);
  };

  const handleRedirectToCart = () => {
    setShowModal(false);
    history.push(PATHS.CART_CHECKOUT);
  };

  if (majorFunctionLoading) {
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <MajorFunctionsList
        selectedFunction={majorEvents}
        onNext={() => onChangeSelectedFunction(true)}
        onPrevious={() => onChangeSelectedFunction(false)}
        onClickAdd={onClickAdd}
        onClickCancel={onClickCancel}
        onClickPurchased={() => console.log("onClickPurchased")}
        isNextDisabled={currentEventIndex === functions.length - 1}
        isPreviousDisabled={currentEventIndex === 0}
      />

      {/* CART */}
      {/* {purchasing && (
        <>
          <Footer isOpen onClose={() => {}}>
            <div className={styles.footerAddToCart}>
              <div className={styles.cartPrice}>
                <b>{events?.length || 0} </b> Events{" "}
                <b>x {tickets?.length || 0} </b> Tickets ={" "}
                <b>${total.toFixed(2)}</b>
              </div>

              <div className={styles.btn}>
                <Button
                  title="Add to Cart"
                  onClick={onRedirectToCart}
                  className={styles.add}
                />
                <Button
                  title="Buy Now"
                  onClick={onClickBuyNow}
                  className={styles.buy}
                />
              </div>
            </div>
          </Footer>

          <div className={styles.addPopup}>
            <CartPopup isOpen={isAddToCartOpen} />
          </div>

          <EmergencyContactModal
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);
            }}
            onClick={handleRedirectToCart}
          />
        </>
      )} */}
    </div>
  );
};
