import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CartItem, ICartItem } from "../CartItem/CartItem";
import styles from "./CartList.module.css";
import { Selection } from "../../components/Selection/Selection";
import { useCart } from "stores/CartContext";
import Button from "components/Button";
import CartSpecialNeedsModal from "components/CartSpecialNeedsModal";
import AdaCompanionIconGreen from "assets/icons/ADA_Companion_Green.svg";
import AdaSeatingIconGreen from "assets/icons/ADA_Seating_Green.svg";
import HearingIconGreen from "assets/icons/Hearing_Device_Green.svg";
import SignLanguageIconGreen from "assets/icons/Sign_Language_Green.svg";
import CartInfoItem from "components/CartInfoItem";
import { CardListModal } from "components/CardListModal/CardListModal";
import { useCards } from "components/CardListModal/useCards";
import Loader from "components/Loader";

interface ICartItemsGrouped {
  [key: string]: ICartItem[];
}

interface ICartListProps {
  items: ICartItem[];
  location?: string;
  eventDate?: string;
  name?: string;
  category?: string;
  credits?: number;
  onItemsChange: Function;
  isCartPage: boolean;
  onDelete: (ticketId: number, userId: number, eventId: number) => void;
}

// const groupItemsByEventId = (items) => {
//   return items.reduce((acc, item) => {
//     const groupId = item.eventId;
//     if (!acc[groupId]) {
//       acc[groupId] = [];
//     }
//     acc[groupId].push(item);
//     return acc;
//   }, {});
// };

export const CartList = (props: ICartListProps) => {
  const {
    items,
    location,
    eventDate,
    credits,
    isCartPage,
    onItemsChange,
    onDelete,
  } = props;

  const history = useHistory();
  const { data: cards = [], isLoading } = useCards();

  // const [addedItems, setAddedItems] = useState<ICartItemsGrouped>(
  //   groupItemsByEventId(items)
  // );

  const defaultOption = options.find((option) => option.value === "Choose");
  const [valueSelect, setValueSelection] = useState(defaultOption);
  const [specialNeedsOpen, setSpecialNeedsOpen] = useState(false);
  const [selectedSpecialNeedsData, setSelectedSpecialNeedsData] = useState<{
    [iboName: string]: string[];
  }>({});
  const [isOpenCardSelect, setIsOpenCardSelect] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    // Check if there is an existing primary card and set it as selectedCard
    const existingPrimaryCard = cards.find((card) => card.isPrimary);
    if (existingPrimaryCard) {
      setSelectedCard(existingPrimaryCard);
    }
  }, [cards]);

  //Handle delete cart item
  const handleDelete = (ticketId: number, userId: number, eventId: number) => {
    onDelete(ticketId, userId, eventId);
  };

  const isMajorFunctions = items.some(
    (item) => item.category === "Major Functions"
  );

  const iconsInGreen = {
    "Accessible seating": AdaSeatingIconGreen,
    "Accessible seating companion ticket": AdaCompanionIconGreen,
    "Use of a hearing amplification device": HearingIconGreen,
    "ASL Interpreter": SignLanguageIconGreen,
  };

  const handleSpecialNeedsClick = (specialNeedsData: {
    [iboName: string]: string[];
  }) => {
    setSelectedSpecialNeedsData(specialNeedsData);
    setSpecialNeedsOpen(false);
  };

  //Convert price from string to number and calculate subtotal
  const parsePrice = (priceStr: string): number => {
    const priceNumber = parseFloat(priceStr.replace(/[^0-9.-]+/g, ""));
    return isNaN(priceNumber) ? 0 : priceNumber;
  };

  const calculateSubtotal = (items: ICartItem[]): number => {
    return items.reduce((total, item) => {
      const itemTotal = item.users.reduce((userTotal, user) => {
        return userTotal + parsePrice(user.priceStr);
      }, 0);
      return total + itemTotal;
    }, 0);
  };

  const calculateTax = (subtotal: number, taxRate: number): number => {
    return subtotal * taxRate;
  };

  const subTotal = calculateSubtotal(items);
  const taxRate = 0.05; // 5%
  const tax = calculateTax(subTotal, taxRate);
  const orderTotal = subTotal + tax;

  const handleEditOrder = () => {
    history.push("/cart");
  };

  const handleEditCard = (card) => {
    setSelectedCard(card);
    setIsOpenCardSelect(true);
  };

  const handleModalClose = (newSelectedCard) => {
    if (newSelectedCard) {
      setSelectedCard(newSelectedCard);
    }
    setIsOpenCardSelect(false);
  };

  if (isLoading)
    return (
      <div className={styles.loader}>
        <Loader color={"#415364"} size={85} />
      </div>
    );

  return (
    <>
      {isCartPage ? (
        <>
          <div className={styles.container}>
            <div className={styles.checkLocation}>
              <p className={styles.location}>
                {location} - {eventDate}
              </p>
            </div>

            {/* {Object.entries(addedItems).map(([eventId, groupItems], index) => ( */}
            <div>
              {/* {items?.map((item, eventId) => ( */}
              {items.flatMap((item) =>
                item.users.map((user) => (
                  <div
                    className={styles.itemGroup}
                    key={`${item.eventId}-${item.ticketId}-${user.userId}`}
                  >
                    <CartItem
                      key={`${item.eventId}-${item.ticketId}-${user.userId}`}
                      item={item}
                      user={user}
                      onDelete={handleDelete}
                      updatePrice={onItemsChange}
                      isCartPage={isCartPage}
                    />
                  </div>
                ))
              )}
              {/* ))} */}

              {/* {index !== Object.keys(addedItems).length && (
                  <hr className={styles.divider} />
                )} */}
            </div>
            {/* ))} */}

            {credits && <div className={styles.item}></div>}
            <div className={styles.subTotalItem}>
              <span className={styles.subTotalText}>Subtotal</span>
              <span className={styles.subTotal}>${subTotal.toFixed(2)}</span>
            </div>

            {isMajorFunctions && (
              <div className={styles.specialBtnContainer}>
                <Button
                  title="Special Needs?"
                  onClick={() => setSpecialNeedsOpen(true)}
                  className={styles.specialBtn}
                />
              </div>
            )}

            <div className={styles.specialNeedsContainer}>
              {items.map((item, index) => {
                const specialNeeds =
                  selectedSpecialNeedsData[item.iboName] || [];
                return specialNeeds.length > 0 ? (
                  <div key={index} className={styles.iboGroup}>
                    <div className={styles.image}>
                      <img
                        src={item.iboImage}
                        alt="Ibo"
                        className={styles.imageIbo}
                      />
                      <div className={styles.iboName}>{item.iboName}</div>
                    </div>
                    <div className={styles.iconContainer}>
                      {specialNeeds.map((selectedValue) => (
                        <img
                          key={selectedValue}
                          src={iconsInGreen[selectedValue]}
                          alt="Selected Special Needs Icon"
                        />
                      ))}
                    </div>
                  </div>
                ) : null;
              })}
            </div>

            <CartSpecialNeedsModal
              isOpen={specialNeedsOpen}
              onClose={() => setSpecialNeedsOpen(false)}
              onPassIboNeeds={handleSpecialNeedsClick}
              cartItems={items}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.checkoutContainer}>
            <div className={styles.checkLocation}>
              <p className={styles.location}>
                {location} - {eventDate}
              </p>
            </div>

            {/* {Object.entries(addedItems).map(([eventId, groupItems], index) => ( */}
            <div>
              <div className={styles.itemGroup}>
                {/* {groupItems?.map((item) => ( */}
                {items.flatMap((item) =>
                  item.users.map((user) => (
                    <CartItem
                      key={`${item.eventId}-${item.ticketId}-${user.userId}`}
                      item={item}
                      user={user}
                      isCartPage={isCartPage}
                    />
                    // ))}
                  ))
                )}
              </div>
              {/* {index !== Object.keys(addedItems).length && (
                  <hr className={styles.divider} />
                )} */}
            </div>
            {/* ))} */}

            <div className={styles.totalContainer}>
              <div className={styles.taxItem}>
                <span className={styles.taxTitle}>Subtotal</span>
                <span className={styles.amount}>${subTotal.toFixed(2)}</span>
              </div>

              <div className={styles.taxItem}>
                <span className={styles.taxTitle}>Tax</span>
                <span className={styles.amount}>${tax.toFixed(2)}</span>
              </div>
              <div className={styles.orderTotalItem}>
                <span className={styles.orderTotalTitle}>Order Total</span>
                <span className={styles.orderTotal}>
                  ${orderTotal.toFixed(2)}
                </span>
              </div>

              <div className={styles.editBtn}>
                <Button
                  className={styles.button}
                  onClick={handleEditOrder}
                  title={"Edit"}
                />
              </div>
            </div>

            <div className={styles.cardAddressContainer}>
              <CartInfoItem
                address="131 Mockingbird Lane, Mockingbird Heights, CA 99999"
                onEdit={() => {
                  alert("Edit address");
                }}
              />
              <div className={styles.hr}></div>

              {orderTotal > 0 && (
                <CartInfoItem
                  cardInfo={{
                    cardType: selectedCard?.cardType,
                    lastFour: selectedCard?.lastFour,
                  }}
                  onEdit={handleEditCard}
                />
              )}
            </div>
          </div>

          <CardListModal isOpen={isOpenCardSelect} onClose={handleModalClose} />
        </>
      )}
    </>
  );
};

const options = [
  { key: "Some option", value: "Choose" },
  { key: "Other option", value: "OtherOption" },
];
