import React, { FC } from "react";
import EventTicketItem from "components/EventTicketItem";
import styles from "./EventsList.module.css";
import { EventInfo, EventItem } from "stores/homesiteInterface";

interface IProps {
  eventTickets: EventItem[];
  // eventTickets: EventInfo[];
  onClickAdd: Function;
  onClickCancel: Function;
  onClickPurchased: Function;
}

export const EventsList: FC<IProps> = ({
  eventTickets,
  onClickAdd,
  onClickCancel,
  onClickPurchased,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <p>Event</p>
        <p>Tickets</p>
      </div>
      {/* {eventTickets.map((item, index) => ( */}
      {eventTickets.map((item, index) => {
        return (
          <div key={index} className={styles.eventItem}>
            <EventTicketItem
              event={item}
              onClickAdd={(user) => onClickAdd(item, user)}
              // onClickAdd={onClickAdd}
              onClickPurchased={(user) => onClickPurchased(item, user)}
              onClickCancel={(user) => onClickCancel(item, user)}
            />
          </div>
          // ))}
        );
      })}
    </div>
  );
};
