import React from "react";
import styles from "./MajorFunctionsDetail.module.css";
import AboutButton from "components/AboutButton";
import { MajorFunctionsEvent } from "../MajorFunctionsEvent/MajorFunctionsEvent";
// import { IFunction, IFunctionIBO, IItems } from "models";
import DOMPurify from "dompurify";

import {
  IMajorFunctions,
  ITempUserIBO,
  IEventItems,
} from "stores/majorInterface";

interface IProps {
  model: IMajorFunctions;
  //Getting error so temp commented
  // onClickAdd: (ibo: ITempUserIBO, event: IEventItems) => void;
  // onClickPurchased: (ibo: ITempUserIBO, event: IEventItems) => void;
  // onClickCancel: (ibo: ITempUserIBO, event: IEventItems) => void;
  onClickAdd: () => void;
  onClickPurchased: () => void;
  onClickCancel: () => void;
}

export const MajorFunctionsDetail: React.FC<IProps> = (props) => {
  let { model, onClickAdd, onClickPurchased, onClickCancel } = props;

  const ticketHeaderText = DOMPurify.sanitize(model.headerText || "");

  const handleButtonClick = (buttonAction, buttonText) => {
    alert(`Show info ${buttonText}`);
  };

  return (
    <div className={styles.container}>
      {model.primaryButtonAction && model.primaryButtonText && (
        <div className={styles.about}>
          <AboutButton
            title={`${model.primaryButtonText}`}
            onClick={() => {
              handleButtonClick(
                model.primaryButtonAction,
                model.primaryButtonText
              );
            }}
            isSummit={false}
          />

          {model.secondaryButtonAction && model.secondaryButtonText && (
            <AboutButton
              title={`${model.secondaryButtonText}`}
              onClick={() => {
                handleButtonClick(
                  model.secondaryButtonAction,
                  model.secondaryButtonText
                );
              }}
              isSummit={true}
            />
          )}
        </div>
      )}
      <div className={styles.ticketInfo}>
        <p dangerouslySetInnerHTML={{ __html: ticketHeaderText }} />
      </div>

      {/* {model.isAvailableForPurchase && */}
      {model.items && model.templateName !== "Spring Leadership" && (
        <div>
          <div className={styles.eventHeader}>
            <div>Event</div>
            <div>Tickets</div>
          </div>
          <div>
            {model.items &&
              model.items.map((event, index) => (
                <div key={index}>
                  <MajorFunctionsEvent
                    item={event}
                    // event={{}}
                    // event={{
                    //   icon: model.icon,
                    //   price: event.priceStr,
                    //   currencyUnit: event.currencyUnit,
                    // }}
                    // onClickAdd={(ibo) => onClickAdd(ibo, event)}
                    // onClickPurchased={(ibo) => onClickPurchased(ibo, event)}
                    // onClickCancel={(ibo) => onClickCancel(ibo, event)}

                    onClickAdd={() => {
                      onClickAdd();
                    }}
                    onClickPurchased={() => {
                      onClickPurchased();
                    }}
                    onClickCancel={() => {
                      onClickCancel();
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      )}

      {model.templateName === "Spring Leadership" && (
        <div>
          <div className={styles.borderSpring}></div>
          {/* <div className={styles.descriptionText}>{formattedDescription}</div> */}
        </div>
      )}
    </div>
  );
};
