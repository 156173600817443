import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../../environment"

interface CartItem {
    userId: number,
    ticketid: number,
    eventId: number,
}

export function useCartAddPost() {
    const queryClient = useQueryClient();

    return useMutation(
        async (bodyPassed: CartItem[]) => {
        const res = await makeAuthedRequest(
            "/cart/add",
            {
                method: "POST",
                body: JSON.stringify(bodyPassed),
            },
                env.apiBase
            );
            if (res.ok) {
                return res;
            } else {
                const body = await res.json();
                console.error("Error adding cart item - ", body.error);
                throw new Error(body.error);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("cartData");
            },
            onError: (err) => {
                console.error(err);
            },
        }   
    );

}