//Data for each item
export interface EventInfo {
  event: EventItem[];
  // user: UserIBO[];
  // ticket: Ticket[];
}

//Item
export interface EventItem {
  eventId: number;
  title: string;
  subTitle: string;
  speaker: Speaker;
  startsOn: string | null | undefined;
  endsOn: string | null | undefined;
  isAvailable: boolean;
  isVirtual: boolean;
  isInPerson: boolean;
  location: Location;
  eventSessions: EventSessions[];
  notes: Notes[];
  tickets: Tickets[];
  //added
  // users?: UserIBO[];
  users: TempUserIBO[];
}

export const defaultEvent: EventItem = {
  eventId: 0,
  title: "",
  subTitle: "",
  isAvailable: false,
  startsOn: "",
  endsOn: "",
  isInPerson: false,
  isVirtual: false,
  location: {
    line1: "",
    line2: null,
    city: "",
    zip: "",
    venueName: "",
  },
  notes: [],
  speaker: {
    name: "",
    src: "",
  },
  tickets: [],
  eventSessions: [],
  users: [],
};

export interface Location {
  line1: string;
  line2: string;
  city: string;
  zip: string;
  venueName: string;
}

export interface Speaker {
  name: string;
  src: string;
}

export interface LocalFunctionsData {
  eventTemplateId: number;
  templateName: string;
  templateType: string;
  icon: string;
  headerText: string;
  description: string | null;
  promoStartsOn: string;
  promoEndsOn: string;
  primaryButtonAction: PrimaryButtonAction;
  primaryButtonText: string;
  secondaryButtonText: string | null;
  secondaryButtonAction: SecondaryButtonAction | null;
  items: EventItem[];

  // users: UserIBO[];
  user: TempUserIBO[];
}

export interface Notes {
  id: number;
  note: string;
}

export interface PrimaryButtonAction {
  component: string;
  data: {
    contentId: string;
  };
}

interface SecondaryButtonAction {
  // Not sure what data in here
}

export interface Tickets {
  ticketId: number;
  title: string;
  currencyCode: string;
  priceStr: string;
  priority: number;
  acceptedCreditTypeId: number;
  commonItemId: number;
  creditsRequired: number;
  isLimitedToGroups: boolean;
  onlyCreditsAllowed: boolean | null;
  tandemEventId: number;
  tandemTicketId: number;
  tandemTicketStatusId: number;

  status: TicketStatus;
}

export interface EventSessions {
  eventId: number;
  parentSessionId: number;
  title: string;
  image: string;
  sessionId: number;
  sessionNote: string;
  sessionType: string;
  startsOn: string;
  endsOn: string;
  primaryCaption: string;
  secondaryCaption: string;
  room: string;
}

// export interface UserIBO {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }

export interface TempUserIBO {
  Name: string;
  UserId: number;
  src: string;
  tickets: Tickets[];
}

export enum TicketStatus {
  New = 1,
  Selected = 2,
  AddToCart = 3,
  Purchased = 4,
  Included = 5,
  Unavailable = 6,
}

export interface LocationState {
  event: EventItem | null;
  items: EventItem[];
}

// export interface User {
//   Name: string;
//   UserId: number;
//   src: string;
//   tickets: Ticket[];
// }
