import { DomainConf } from "@wwg/domain-conf";

/**
 * Define configurations here to be loaded based on the domain
 * the app is being served from.
 */
const env = new DomainConf()
  .forDomain("localhost", {
    // apiBase: "http://localhost:3750",
    apiBaseOLD: "https://app-events-sales-api-dev.wwdb.com",
    apiBase: "https://dev-api.wwdb.com/v1/events/",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
    localDevMode: true, // Set to true to enable local login for developers.
  })
  .forDomain("dev-ca-events.wwdb.com", {
    apiBaseOLD: "https://app-events-sales-api-dev.wwdb.com",
    apiBase: "https://dev-api.wwdb.com/v1/events/",
    coreApiUrl: "https://app-core-web-dev.wwdb.com",
  })
  .forDomain("app-events-sales-web-staging.wwdb.com", {
    apiBaseOLD: "https://app-events-sales-api-staging.wwdb.com",
    apiBase: "https://dev-api.wwdb.com/v1/events/",
    coreApiUrl: "https://app-core-web-staging.wwdb.com",
  })
  .forDomain("app-events-sales-web.wwdb.com", {
    apiBaseOLD: "https://app-events-sales-api.wwdb.com",
    apiBase: "https://dev-api.wwdb.com/v1/events/",
    coreApiUrl: "https://app-core-web.wwdb.com",
  });

// Export the selected configuration from this file. You can import this wherever you need to use it.
export default env.current;
