import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./HomeSite.module.css";
import CountDown from "components/CountDown";
import { PATHS } from "utils";
import FunctionInfoCard from "components/FunctionInfoCard";
import Notification from "components/Notification";
import HomeSite_Icon from "assets/icons/HomeSite_Icon.svg";
import Map_Icon from "assets/icons/Map_Icon.svg";
import EventsList from "components/EventsList";
import LocationModal from "components/LocationModal";
import { TicketStatus, Ticket, UserIBO } from "stores/homesiteInterface";
import CartPopup from "components/CartPopup";
import { useCart } from "../../stores/CartContext";
import Footer from "components/Footer";
import Button from "components/Button";
import { extractTimeAndTimezone } from "../../utils/formatDate";
import { useHomeSite, useUser } from "./useHomeSite";
import { useCartAddPost } from "./useCartAddPost";
import Loader from "components/Loader";

interface IProps {}

/**
   //example of how the value of selected is treated
      export enum TicketStatus {
        New = 1,
        Selected = 2,
        AddToCart = 3,
        Purchased = 4,
        Included = 5,
        Unavailable = 6,
      }
 */

// Function to update the eventTicketsList based on ticket status change
export const HomeSite: React.FC<IProps> = (props) => {
  const history = useHistory();
  const { addToCart } = useCart();
  const {
    data,
    isLoading: homesiteLoading,
    isSuccess,
    refetch,
  } = useHomeSite();
  const {
    isLoading: userLoading,
    isSuccess: userIsSuccess,
    isError: userIsError,
    data: userData,
    error: userError,
    refetch: userRefetch,
  } = useUser();

  const cartAddPostMutate = useCartAddPost();

  const [isOpen, setIsOpen] = useState(false);
  const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
  const [isAddToCartVisible, setIsAddToCartVisible] = useState(false);

  //holds all selected tickets (eventId, ticketId, userId)
  const [selectedItems, setSelectedItems] = useState([]);
  //temporary state for holding full api call
  const [tempData, setTempData] = useState(null);
  //temp state for holding user api call
  const [apiUsers, setApiUsers] = useState(null);

  //react-query data set
  useEffect(() => {
    if (isSuccess && data) {
      setTempData(data);
    }
  }, [homesiteLoading, data]);

  useEffect(() => {
    if (userIsSuccess && userData) {
      setApiUsers(userData);
    }
  }, [userLoading, userData]);

  //manipulated api data
  //used specifically for the functionInfoCard
  // const [subEventsHolder, setsubEventsHolder] = useState([]);
  //manipulated notes for pinNotes (concatenated via string)
  const [pinNotesHolder, setPinNotesHolder] = useState("");
  //holds a full list of subevents (manipulated to conform to necessary existing data structure, holds all values from response(some concatenated))
  const [eventsWithIbo, setEventsWithIbo] = useState([]);

  useEffect(() => {
    if (isAddToCartOpen && selectedItems.length > 0) {
      cartAddPostMutate.mutate(selectedItems);
      //refetch users data
      // userRefetch();

      //clear the temp cart (selected items, ticketStatus.Selected)
      setSelectedItems([]);
    }
  }, [selectedItems, isAddToCartOpen]);

  //use effect to call functions that set values to their existing data structure
  useEffect(() => {
    if (tempData && tempData.eventSet && apiUsers) {
      // handleSubEventsSet(tempData);
      handleIboSet(tempData.eventSet);
    }
    if (tempData && tempData.pinNotes) {
      handlePinNotesSet(tempData.pinNotes);
    }
  }, [tempData, userData]);

  //used for functionInfoCard
  // const handleSubEventsSet = (oldTempData) => {
  //   let subEvents = [];
  //   oldTempData?.eventSet.forEach((subEvent) => {
  //     // Creating an object from event properties to pass to functionInfoCard

  //     //concatenating all of the notes into one string to be passed to card
  //     let subEventDescription = "";
  //     subEvent.notes.forEach((item) => {
  //       subEventDescription += item.note + " ";
  //     });

  //     //required structure for functionInfoCard component
  //     let eventFunctionInfoCardInfo = {
  //       fullName: subEvent.title,
  //       startTime: dateToFormatted(subEvent.startsOn),
  //       endTime: dateToFormatted(subEvent.endsOn),
  //       description: subEventDescription,
  //     };

  //     subEvents.push(eventFunctionInfoCardInfo);
  //   });
  //   setsubEventsHolder(subEvents);
  // };

  //used for notification (pin notes)
  const handlePinNotesSet = (oldPinNotes) => {
    let retString = "";

    oldPinNotes.forEach((string) => {
      retString += string + ". ";
    });

    retString = retString.trim();
    setPinNotesHolder(retString);
  };

  //used to transform array into a single string used in children
  //does not include venueName
  const concatenateLocationToString = (locationArray) => {
    let retString = "";

    for (const key in locationArray) {
      if (locationArray.hasOwnProperty(key)) {
        if (key !== "venueName") {
          retString += locationArray[key] + " ";
        }
      }
    }

    retString = retString.trim();
    return retString;
  };

  //creates a more detailed data structure for child component implementation, also used for
  //setting cart item onclick events (purchasing)
  const handleIboSet = (events) => {
    //if the ibos array is missing from the specific event
    let retArray = [];
    //append the ibos to the event
    events.forEach((subEvent) => {
      let subEventDescription = "";
      subEvent.notes.forEach((item) => {
        subEventDescription += item.note + ". ";
      });
      subEventDescription.trim();

      let newIboStructure = apiUsers.map((user) => {
        // Find matching tickets for the subEvent
        const matchingTickets = user.tickets.filter(
          (ticket) =>
            ticket.ticketId === subEvent.ticketId &&
            ticket.eventId === subEvent.eventId
        );

        //construct the new ibo object only with values the children need
        //api response includes ticket id because it is seperate,
        //in this version we are making one response that pairs the event, with the users
        //and treats the event with the users inside as one object pulling the ticket status
        //if applicable, otherwise sets the ticket status as new
        return {
          UserId: user.UserId,
          src: user.src,
          Name: user.Name,
          // ticketStatus: ticketStatus,
          tickets: matchingTickets,
        };
      });

      let eventToPass = {
        eventId: subEvent.eventId,
        ticketId: subEvent.ticketId,
        // icon: handleIconSet(subEvent.title),
        title: subEvent.title,
        speaker: subEvent.speaker,
        location: subEvent.location,
        startsOn: subEvent.startsOn, //used as startTime
        endsOn: subEvent.endsOn, //used as endTime
        // startsTime: subEvent.startsOn, //used as startTime
        // endsTime: subEvent.endsOn,
        description: subEventDescription,
        note: subEventDescription, //same as description???
        isVirtual: subEvent.isVirtual,
        isInPerson: subEvent.isInPerson,
        currencyCode: subEvent.currencyCode,
        isAvailableForPurchase: subEvent.isAvailable, //todo, figure out expected functionality here
        users: newIboStructure,
        price: subEvent.priceStr,
      };

      retArray.push(eventToPass);
    });

    setEventsWithIbo(retArray);
  };

  //formats the date from a date object to a string
  // const dateToFormatted = (date) => {
  //   let dateFormatted = extractDate(date);
  //   let timeFormatted = extractTime(date);

  //   let finalDateFormat = `${dateFormatted} ${timeFormatted}`;
  //   return finalDateFormat;
  // };

  //gets currency symbol based off of passed currrencyCode value (e.g. 'USD', 'JPY', 'EUR', etc)
  //without using regex.
  const getCurrencySymbol = (currCode) => {
    //only english users?
    const formatCurrency = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const parts = formatCurrency.formatToParts(0);

    const currencySymbol = parts.find((part) => part.type === "currency").value;

    return currencySymbol;
  };

  //Triggers the add button in user
  const onClickAdd = (event, user) => {
    let newIboMap = eventsWithIbo.map((item) => {
      if (item.ticketId === event.ticketId) {
        return {
          ...item,
          users: item.users.map((eventUser) => {
            if (eventUser.UserId === user.UserId) {
              return {
                ...eventUser,
                tickets: eventUser.tickets.map((ticket) => {
                  if (ticket.ticketId === event.ticketId) {
                    return { ...ticket, status: TicketStatus.Selected };
                  }
                  return ticket;
                }),
              };
            }
            return eventUser;
          }),
        };
      }
      return item;
    });

    setEventsWithIbo(newIboMap);

    const cartItemToAdd = {
      eventId: event.eventId,
      ticketId: event.ticketId,
      userId: user.UserId,
    };

    setSelectedItems((prev) => [...prev, cartItemToAdd]);
  };

  //Triggers the cancel button in user
  const onClickCancel = (event, user) => {
    let newIboMap = eventsWithIbo.map((eventSet) => {
      if (eventSet.ticketId === event.ticketId) {
        return {
          ...eventSet,
          users: eventSet.users.map((eventUser) => {
            if (eventUser.UserId === user.UserId) {
              return {
                ...eventUser,
                tickets: eventUser.tickets.map((ticket) => {
                  if (ticket.ticketId === event.ticketId) {
                    return { ...ticket, status: TicketStatus.New };
                  }
                  return ticket;
                }),
              };
            }
            return eventUser;
          }),
        };
      }
      return eventSet;
    });
    setEventsWithIbo(newIboMap);

    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.filter((item) => {
        return !(
          event.ticketId === item.ticketId && user.UserId === item.UserId
        );
      });
    });
  };

  //#region
  const newPurchasing = eventsWithIbo.some((event) =>
    event.users.some((user) =>
      user.tickets.some((ticket) => ticket.status === TicketStatus.Selected)
    )
  );

  let events = null;
  let tickets = null;
  let total = 0;

  if (newPurchasing) {
    events = eventsWithIbo.filter((event) =>
      event.users.some((user) =>
        user.tickets.some((ticket) => ticket.status === TicketStatus.Selected)
      )
    );

    tickets = events.flatMap((event) =>
      event.users.flatMap((user) =>
        user.tickets.filter((ticket) => ticket.status === TicketStatus.Selected)
      )
    );

    total = eventsWithIbo.reduce((acc, event) => {
      const numSelectedTickets = event.users.reduce((sum, user) => {
        return (
          sum +
          user.tickets.reduce((ticketSum, ticket) => {
            return (
              ticketSum + (ticket.status === TicketStatus.Selected ? 1 : 0)
            );
          }, 0)
        );
      }, 0);

      return acc + numSelectedTickets * event.price;
    }, 0);
  }

  //#endregion

  const onRedirectToCart = () => {
    setIsAddToCartOpen(true);
    setTimeout(() => {
      setIsAddToCartOpen(false);
      history.push(PATHS.CART);
    }, 700);
  };

  const onRedirectToCartCheckout = () => {
    history.push(PATHS.CART_CHECKOUT);
  };

  const handleTicketAdded = () => {
    setIsAddToCartVisible(true);
  };

  //used to test time countdown (2 min from now)
  // const currentTime = new Date().toISOString();
  // const now = new Date();
  // const twoMinutesFromNow = new Date(now.getTime() + 2 * 60 * 1000);
  // const isoString = twoMinutesFromNow.toISOString();

  // (tempData?.eventSet?.[0]?.startsOn)

  const startTime = tempData
    ? extractTimeAndTimezone(tempData?.eventSet?.[0]?.startsOn)
    : "";
  const endTime = tempData
    ? extractTimeAndTimezone(tempData?.eventSet?.[0]?.endsOn)
    : "";

  if (homesiteLoading && userLoading) {
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {/* because the temp data is the indication that we got a successful response from react-query */}

      {tempData && eventsWithIbo?.length > 0 && (
        <>
          {/* 
            at the time of building this the response form the api was onl a single Object.
            If the response is an array of objects, then a loop through the objects is necessary here
            Note, that a call of the funciton handleSubEventsSet(item[index]) before each render of the
            function info card would be neccessary to populate the events object that is paassed to the funciton info card
            
            as well as the call of the function handlePinNotesSet(item[index]) to render pin note for event
            
            i.e. 
            
            tempData.map((item, index) => (
              handleSubEventsSet(item[index])
              handlePinNotesSet(item[index])
              or something like this
              
              REST OF WHOLE BODY
              ))
              
              also note the use of eventsWithIbo could be used here (different keys to match child components)
          */}
          <div className={styles.header}>
            <div className={styles.logo}>
              <img src={HomeSite_Icon} alt="HomeSite Icon" />
            </div>
            <div className={styles.countMapContainer}>
              <div className={styles.countDown}>
                <div className={styles.location}>
                  {eventsWithIbo[0].location?.line1}
                </div>

                <CountDown
                  // date="2024-07-04T01:00:00.000Z"
                  date={eventsWithIbo[0].startsOn || ""}
                  // date={eventsWithIbo[2].startTime}

                  //mock 2 minutes from now to test countdown functionality (uncomment date={isoString})
                  // date={isoString}
                  onSaleDate={""}
                  // location=""
                />
              </div>
              <div className={styles.map} onClick={() => setIsOpen(true)}>
                <img
                  src={Map_Icon}
                  alt="Map Icon"
                  className={styles.map}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <FunctionInfoCard
              //setting first item in event set as the main event for now
              //eventually needs to be adjusted based on popularity etc.
              priceStr={`${getCurrencySymbol(eventsWithIbo[0].currencyCode)}${
                eventsWithIbo[0].price
              }`}
              sessions={[]}
              speakerInfo={{
                //   image: eventsWithIbo[0].speaker.src,
                //   firstName: eventsWithIbo[0].speaker.name,
                // lastName: "MAIN EVENT",

                name: eventsWithIbo[0].speaker.name,
                image: eventsWithIbo[0].speaker.src,
              }}
              // eventInfo={subEventsHolder}
              //example of how the eventInfo prop is handled.
              // eventInfo={{
              //   // refering to title
              //   title: tempData?.eventSet?.title,
              // startTime: dateToFormatted(tempData?.eventSet?.[0]?.startsOn),
              // endTime: dateToFormatted(tempData?.eventSet?.[0]?.endsOn),
              //   description: "Not sure what data to display",
              // }}

              eventInfo={eventsWithIbo.map((event) => ({
                title: event.title,
                startTime: startTime.split(" ")[0],
                endTime: `${endTime.split(" ")[0]} ${endTime
                  .split(" ")[1]
                  .toLowerCase()}`,
                description: event.description || "No description available",
              }))}
            />

            <div className={styles.noti}>
              <Notification
                className="#243746"
                notification={pinNotesHolder}
                img=""
              />
            </div>

            {/* {eventsWithIbo && ( */}
            <EventsList
              eventTickets={eventsWithIbo}
              onClickAdd={(event, user) => onClickAdd(event, user)}
              // onClickAdd={onClickAdd}
              onClickCancel={(event, ibo) => {
                onClickCancel(event, ibo);
              }}
              onClickPurchased={() => {}}
            />
            {/* )} */}
          </div>

          {isOpen && (
            <LocationModal
              venueName={eventsWithIbo[0].location.venueName}
              // address="12021 Harbor BLVD Garden Grove, CA 92840 USA"
              address={concatenateLocationToString(eventsWithIbo[0].location)}
              onChangeVisible={(visible) => setIsOpen(visible)}
            />
          )}

          {newPurchasing && (
            <>
              <Footer isOpen onClose={() => {}}>
                <div className={styles.footerAddToCart}>
                  <div className={styles.cartPrice}>
                    <b>{events?.length || 0}</b> Events{" "}
                    <b>x {tickets?.length || 0}</b> Tickets ={" "}
                    <b>
                      {getCurrencySymbol(eventsWithIbo[0].currencyCode)}
                      {total.toFixed(2)}
                    </b>
                  </div>
                  <div className={styles.btn}>
                    <Button
                      title="Add to Cart"
                      onClick={onRedirectToCart}
                      className={styles.add}
                    />
                    <Button
                      title="Buy Now"
                      onClick={onRedirectToCartCheckout}
                      className={styles.buy}
                    />
                  </div>
                </div>
              </Footer>

              <CartPopup isOpen={isAddToCartOpen} />
            </>
          )}
        </>
      )}
    </div>
  );
};
