import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../../environment"


export function useHomeSite() {
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
    useQuery(["homesite"], () =>
        //apiBase not defaulting properly, imported env and forced apiBase.
      makeAuthedRequest(`/homesite`, { method: "get" }, env.apiBase)
        .then(async (res) => {
            const json = await res.json();
            if (res.ok) {
                return json;
            } else {
                throw new Error(json.message);
            }
        })
    );

  return {
    isLoading,
    isSuccess,
    isError,
    data,
    error,
    fetch,
    refetch,
  };
}

export function useUser() {
    const queryClient = useQueryClient();

    const { isLoading, isSuccess, isError, data, error, fetch, refetch } =
      useQuery(["user"], () =>
          //apiBase not defaulting properly, imported env and forced apiBase.
        makeAuthedRequest(`/user`, { method: "get" }, env.apiBase)
          .then(async (res) => {
              const json = await res.json();
              if (res.ok) {
                  return json;
              } else {
                  throw new Error(json.message);
              }
          })
      );

    return {
        isLoading,
        isSuccess,
        isError,
        data,
        error,
        fetch,
        refetch,
    };
}