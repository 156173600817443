import React, { useState, useEffect } from "react";
import styles from "./LocalFunctionsEvent.module.css";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CountDown from "components/CountDown";
import { Alert } from "../components/Alert";
import Notification from "components/Notification";
import { useCart } from "stores/CartContext";
import {
  EventItem,
  EventInfo,
  LocalFunctionsData,
  Tickets,
  TicketStatus,
  LocationState,
  TempUserIBO,
  EventSessions,
} from "stores/interfaces.types";
import LocalFunctionEventsList from "components/LocalFunctionComponents/LocalFunctionEventsList";
import LocalFunctionInfoCard from "components/LocalFunctionComponents/LocalFunctionInfoCard";
import Map_Icon from "assets/icons/Map_Icon.svg";
import LocationModal from "components/LocationModal";
import { extractTimeAndTimezone, formatDateRange } from "utils/formatDate";
import { formatCurrency } from "utils/formatCurrency";
import Footer from "components/Footer";
import Button from "components/Button";
import { PATHS } from "utils";
import CartPopup from "components/CartPopup";
import ArrowLeftIcon from "./Arrow_Left.svg";
import Loader from "components/Loader";
import { useUser } from "stores/useUser";

// interface ILocalFunctionsEventProps {
//   onAddToCart: (item: any) => void;
// }

interface ILocalFunctionsEventProps {
  // meetings: Meeting[];
  // items: EventItem[];
}

interface RouteParams {
  eventId: string;
}

const defaultEvent: EventItem = {
  eventId: 0,
  title: "",
  subTitle: "",
  isAvailable: false,
  startsOn: "",
  endsOn: "",
  isInPerson: false,
  isVirtual: false,
  location: {
    line1: "",
    line2: null,
    city: "",
    zip: "",
    venueName: "",
  },
  notes: [],
  speaker: {
    name: "",
    src: "",
  },
  eventSessions: [],
  tickets: [],
  users: [],
};

export const LocalFunctionsEvent = React.memo(
  (props: ILocalFunctionsEventProps) => {
    // const { items } = props;
    // const location = useLocation();
    const history = useHistory();
    const { addToCart } = useCart();
    const { eventId } = useParams<RouteParams>();
    const location = useLocation<LocationState>();
    // const { data, isLoading } = useLocalFunctions();
    const { data: userData, isLoading: userLoading } = useUser();

    const [isOpen, setIsOpen] = useState(false);
    const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
    // const [eventDetails, setEventDetails] = useState(null);

    // const [eventSessions, setEventSessions] = useState<EventSessions[]>([]);

    const [eventDetails, setEventDetails] = useState<EventItem | null>(
      defaultEvent
    );

    const [eventSessions, setEventSessions] = useState<EventSessions[]>([]);

    const [selectedItems, setSelectedItems] = useState([]);

    const [items, setItems] = useState<EventItem[]>(
      location.state?.items || []
    );

    // console.log("LOCAL EVENR:", items);

    // console.log("BEFORE eventDetails", eventDetails);
    // console.log("SESSION eventSessions", eventDetails.eventSessions);

    //ALL event Info data
    // useEffect(() => {
    //   if (!items || !eventId) return;

    //   // Find the event with the specified eventId
    //   const event = items.find(
    //     (item: EventItem) => item.eventId === parseInt(eventId)
    //   );

    //   // Debugging logs
    //   console.log("Found Event:", event);

    //   if (event) {
    //     const eventInfo = {
    //       ...event,
    //       eventId: event.eventId,
    //       title: event.title,
    //       subTitle: event.subTitle,
    //       speaker: event.speaker,
    //       location: event.location,
    //       startTime: event.startsOn,
    //       endTime: event.endsOn,
    //       note: event.notes,
    //       isAvailable: event.isAvailable,
    //       isVirtual: event.isVirtual,
    //       isInPerson: event.isInPerson,
    //       ticket: event.tickets,
    //       eventSessions: event.eventSessions,
    //       // users: dummyUsersData,
    //     };
    //     // setEventDetails(event);
    //     setEventDetails(eventInfo);

    //     console.log("event", event);
    //   }
    // }, [eventId, items, userData, history]);

    //Include only the eventSessions data
    useEffect(() => {
      if (!items || !eventId) return;

      // Find the event with the specified eventId
      const event = items.find(
        (item: EventItem) => item.eventId === parseInt(eventId)
      );

      if (event) {
        const eventInfo = { ...event };
        setEventDetails(eventInfo);
        setEventSessions(event.eventSessions);
      }
    }, [eventId, items, userData, history]);

    // if (event) {
    //   const matchingUsers = userData
    //     .filter((user: UserIBO) =>
    //       user.tickets.some(
    //         (ticket: Ticket) => ticket.ticketId === event.ticket.ticketId
    //         // &&
    //         //   ticket.eventId === event.eventId
    //       )
    //     )
    //     .map((user: UserIBO) => ({
    //       ...user,
    //       tickets: user.tickets.filter(
    //         (ticket: Ticket) => ticket.ticketId === event.ticketId
    //         // &&
    //         //   ticket.eventId === event.eventId
    //       ),
    //     }));

    //   const eventInfo = {
    //     ...event,
    //     name: event.title,
    //     speaker: event.speaker,
    //     location: event.location,
    //     startTime: event.startsOn,
    //     endTime: event.endsOn,
    //     description: event.notes ? event.notes.note : "", //not sure of this
    //     note: event.notes ? event.notes.note : "",
    //     isVirtual: event.isVirtual,
    //     isInPerson: event.isInPerson,
    //     users: matchingUsers,
    //     priceStr: priceStr,
    //   };

    //   setEventDetails(eventInfo);
    // } else {
    //   console.error("Event ticket not found");
    // }
    // }
    // }, [eventId, items, userData]);

    //Adding to cart
    useEffect(() => {
      if (isAddToCartOpen && selectedItems.length > 0) {
        selectedItems.forEach((item) => addToCart(item));
        setSelectedItems([]);
      }
    }, [selectedItems, isAddToCartOpen, addToCart]);

    //Triggers the add button in user
    const onClickAdd = (user) => {
      const updatedUsers = eventDetails.users.map((item) =>
        item.UserId === user.UserId
          ? // ? { ...item, status: TicketStatus.Selected }
            // : item

            {
              ...item,
              // tickets: item.tickets.map((ticket) =>
              //   ticket.ticketId === user.tickets[0].ticketId
              //     ? { ...ticket, status: TicketStatus.Selected }
              //     : ticket
              // ),
            }
          : item
      );
      setEventDetails({ ...eventDetails, users: updatedUsers });

      const newCartItem = {
        ...eventDetails,
        // price: priceStr,
      };

      setSelectedItems((prev) => [...prev, newCartItem]);
      // alert("Add ticket clicked");
    };

    //Ticket or event count shows in the footer
    // const ticketCount =
    //   eventDetails?.users
    //     ?.flatMap((user) => user.tickets)
    //     .filter((ticket) => ticket.status === TicketStatus.Selected).length ||
    //   0;

    // const total = eventDetails?.users
    //   ?.flatMap((user) => user.tickets)
    //   .filter((ticket) => ticket.status === TicketStatus.Selected);
    // .reduce((acc, ibo) => acc + tempPrice, 0) || 0;

    //Triggers the cancel button in user
    const onClickCancel = (user) => {
      // const updatedUsers = eventDetails.users.map((item) =>
      //   item.UserId === user.UserId
      //     ? {
      //         ...item,
      //         tickets: item.tickets.map((ticket) =>
      //           ticket.ticketId === user.tickets[0].ticketId
      //             ? { ...ticket, status: TicketStatus.New }
      //             : ticket
      //         ),
      //       }
      //     : item
      // );

      // setEventDetails({ ...eventDetails, users: updatedUsers });

      // Remove from the temporary list
      setSelectedItems((prev) =>
        prev.filter((item) => item.iboName !== user.Name)
      );
    };

    const onRedirectToCart = () => {
      setIsAddToCartOpen(true);
      setTimeout(() => {
        setIsAddToCartOpen(false);
        history.push(PATHS.CART);
      }, 700);
    };

    const onRedirectToCartCheckout = () => {
      history.push(PATHS.CART_CHECKOUT);
    };

    //Dates and time
    // const promoStartsOn = new Date(eventDetails?.startsOn);
    // const promoEndsOn = new Date(eventDetails?.endsOn);

    // const onSaleDate =
    //   promoStartsOn && promoEndsOn
    //     ? formatDateRange(promoStartsOn, promoEndsOn)
    //     : "";

    //Function triggers in clicking locatin map
    const locationMap = (locationArray) => {
      let retString = "";

      for (const key in locationArray) {
        if (locationArray.hasOwnProperty(key)) {
          if (key !== "venueName") {
            // Add comma after line1 and city
            if (key === "line1" || key === "city") {
              retString += locationArray[key] ? locationArray[key] + ", " : "";
            } else {
              retString += locationArray[key] ? locationArray[key] + " " : "";
            }
          }
        }
      }

      retString = retString.trim();
      return retString;
    };

    //Extract the eventSessions with eventType === "EVENT"
    const eventTypeSessions = eventSessions.filter(
      (session) => session.sessionType === "EVENT"
    );

    //Extract the eventSessions with eventType === "EVENT"
    const allTypeSessions =
      eventSessions.filter((session) => session.sessionType === "SESSION") ||
      [];

    //Extract the eventSessions
    const startTime = eventTypeSessions
      ? extractTimeAndTimezone(eventTypeSessions[0]?.startsOn)
      : "";

    const endTime = eventTypeSessions
      ? extractTimeAndTimezone(eventTypeSessions[0]?.endsOn)
      : "";

    // console.log("eventTypeSessions", eventTypeSessions);

    //Get the price from the first ticket with the priority === 1
    const getPriceStr = (tickets) => {
      const priorityOneTicket = tickets.find((ticket) => ticket.priority === 1);

      if (priorityOneTicket) {
        const price = parseFloat(priorityOneTicket.priceStr);
        return formatCurrency(price, priorityOneTicket.currencyCode);
      } else {
        return "0.00";
      }
    };

    const priceStr = eventDetails ? getPriceStr(eventDetails.tickets) : "";

    if (!eventDetails) {
      return (
        <div>
          <Loader color={"#415364"} size={85} />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.header} onClick={() => history.goBack()}>
          <img
            src={ArrowLeftIcon}
            alt={"Back Icon"}
            className={styles.backIcon}
          />
          <span className={styles.headerText}>Local Functions</span>
        </div>
        {/* <div> */}
        <div className={styles.topContainer}>
          <div className={styles.locationMapContainer}>
            <div className={styles.location}>
              {/* {eventDetails?.location?.line1} */}
              {eventDetails.title}
            </div>
            <div className={styles.map} onClick={() => setIsOpen(true)}>
              <img
                src={Map_Icon}
                alt="Map Icon"
                className={styles.mapIcon}
                onClick={() => setIsOpen(true)}
              />
            </div>
          </div>

          <div className={styles.countDown}>
            <CountDown
              date={eventDetails?.startsOn || null}
              onSaleDate={eventDetails?.startsOn || null}
              // onSaleDate={""}
            />
          </div>
          {/* </div> */}
        </div>

        <div className={styles.body}>
          <LocalFunctionInfoCard
            speakerInfo={
              eventTypeSessions.length > 0
                ? {
                    firstName: `${eventTypeSessions[0].primaryCaption}`,
                    lastName: `${eventTypeSessions[0].secondaryCaption}`,

                    image: eventTypeSessions[0].image,
                  }
                : null
            }
            // sessions={null}
            // speakerInfo={
            //   eventDetails?.speaker
            //     ? {
            //         name: eventDetails.speaker.name,
            //         image: eventDetails.speaker.src,
            //       }
            //     : null
            // }

            sessionInfo={
              eventTypeSessions.length > 0
                ? {
                    title: eventTypeSessions[0].title,
                    startTime: startTime,
                    endTime: endTime,
                    description: eventTypeSessions[0].sessionNote,
                  }
                : null
            }
            priceStr={priceStr}
          />

          {eventDetails?.notes?.map((item, index) => (
            <div className={styles.alert} key={index}>
              <Alert note={item.note} />
            </div>
          ))}

          {/* //No pinNote */}
          {/* {eventDetails?.pinNotes?.map((note, index) => (
            <div className={styles.noti} key={index}>
              <Notification
                className="#243746"
                notification={note.pinNote}
                img=""
              />
            </div>
          ))} */}

          {/* //Not sure if this is included
          <Note
            icon={<QuestionMarkIcon fill="#fff" fillText="#243746" />}
            content="This meeting is tentative."
          /> */}

          <div className={styles.section}>
            <div className={styles.eventItem}>
              <LocalFunctionEventsList
                // eventTickets={eventDetails.tickets}
                eventSessions={allTypeSessions}
                users={eventDetails.users}
                // onClickAdd={(event, user) => onClickAdd(event, user)}
                onClickAdd={onClickAdd}
                onClickCancel={onClickCancel}
                // onClickCancel={(event, ibo) => {
                //   onClickCancel(event, ibo);
                // }}
                onClickPurchased={() => {}}
              />
            </div>
          </div>
        </div>

        {isOpen && (
          <LocationModal
            venueName={eventDetails.location.venueName}
            address={locationMap(eventDetails.location)}
            onChangeVisible={(visible) => setIsOpen(visible)}
          />
        )}

        {/* TODO: FOOTER Getting error */}
      </div>
    );
  }
);

//FOOTER
//  {ticketCount > 0 && (
// <>
// <Footer isOpen onClose={() => {}}>
//   <div className={styles.footerAddToCart}>
//     <div className={styles.cartPrice}>
//       <b>{ticketCount > 0 ? 1 : 0}</b> Events <b>x {ticketCount}</b>{" "}
//       Tickets = <b>${total.toFixed(2)}</b>
//     </div>
//     <div className={styles.btn}>
//       <Button
//         title="Add to Cart"
//         onClick={onRedirectToCart}
//         className={styles.add}
//       />
//       <Button
//         title="Buy Now"
//         onClick={onRedirectToCartCheckout}
//         className={styles.buy}
//       />
//     </div>
//   </div>
// </Footer>

// <CartPopup isOpen={isAddToCartOpen} />
// </>
//  )}
