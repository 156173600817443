import React from "react";
import styles from "./UpcomingMeetingsList.module.css";
import PinIcon from "components/PinIcon";
import QuestionMarkIcon from "components/QuestionMarkIcon";
import LockIcon from "components/LockIcon";
import { EventItem, Tickets } from "../../stores/interfaces.types";
import { Alert } from "pages/LocalFuntions/components/Alert";
import Modal from "components/Modal";
import { formatDate, extractDate, extractTime } from "utils/formatDate";
import { formatTitleCase } from "utils/formatName";
import { getIconByTitle } from "../LocalFunctionComponents/getIconByTitle";

interface IUpcomingMeetingsListProps {
  // meetingDate: string;
  items: EventItem[];
  icon: string;
  // selectedMeetingItem: EventItem;
  // history: any;
  // onRedirect: () => void;
  // selectedEvent: EventItem | null;
  selectedMeetingItem: (eventId: number) => void;
}

export const UpcomingMeetingsList = React.memo(
  (props: IUpcomingMeetingsListProps) => {
    const { items, icon, selectedMeetingItem } = props;
    const [openModalPin, onShowModalPin] = React.useState(false);
    const [openModalQuestion, onShowModalQuestion] = React.useState(false);
    const [openModalLocked, onShowModalLocked] = React.useState(false);

    const [selectedMeeting, setSelectedMeeting] =
      React.useState<EventItem | null>(null);

    //Filter the items with eventSessions and sort the date
    const filteredItems = [...items]
      .filter((item) => item.eventSessions && item.eventSessions.length > 0)
      .sort(
        (a, b) =>
          new Date(a.startsOn).getTime() - new Date(b.startsOn).getTime()
      );

    // Group items by month and date
    const groupedItems = filteredItems.reduce((groups, item) => {
      const date = new Date(item.startsOn).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    const renderModalPin = () => {
      return (
        <Modal
          icon={<PinIcon fill="#91B6BB" />}
          isOpen={openModalPin}
          onClose={() => onShowModalPin(false)}
        >
          <React.Fragment>
            <div className={styles.modalHeader}>Note</div>
            <div className={styles.modalContent}>
              The parking lot is full. You will need to park across the street
              in the Church. You will possibly be charged $10.00 for parking.
            </div>
          </React.Fragment>
        </Modal>
      );
    };

    const renderModalQuestion = () => {
      return (
        <Modal
          icon={<QuestionMarkIcon fill="#91B6BB" fillText="#000" />}
          isOpen={openModalQuestion}
          onClose={() => onShowModalQuestion(false)}
        >
          <React.Fragment>
            <div className={styles.modalHeader}>This meeting is tentative</div>
            <div className={styles.modalContent}>
              We try our best to have all of the meeting information set as
              early as possible. Sometimes we need to change an aspect of the
              meeting.
            </div>
          </React.Fragment>
        </Modal>
      );
    };

    const renderModalLocked = () => {
      return (
        <Modal
          icon={<LockIcon isLocked={true} />}
          isOpen={openModalLocked}
          onClose={() => onShowModalLocked(false)}
        >
          <React.Fragment>
            <div className={styles.modalHeader}>
              This meeting is not your HomeSite
            </div>
            <div className={styles.modalContent}>
              You <strong>can attend</strong> this meeting, however tickets
              aren’t for sale until two weeks before the event.
            </div>
          </React.Fragment>
        </Modal>
      );
    };

    const onClickLockIcon = (isAvailable: boolean, eventId) => {
      const isLocked = !isAvailable;
      if (isLocked) {
        onShowModalLocked(true);
      } else {
        selectedMeetingItem(eventId);
      }
    };

    return (
      <div className={styles.meetingList}>
        {Object.keys(groupedItems).map((date) => (
          <div key={date}>
            <div className={styles.headerDate}>{formatDate(date)}</div>
            {groupedItems[date].map((item, index) => {
              const isLocked = !item.isAvailable;

              return (
                <div key={index} className={styles.meeting}>
                  <div
                    className={styles.meetingInfo}
                    onClick={() =>
                      onClickLockIcon(item.isAvailable, item.eventId)
                    }
                  >
                    <div className={styles.meetingInfoLeft}>
                      <div className={styles.meetingIcon}>
                        <img
                          className={styles.icon}
                          src={getIconByTitle(item.subTitle)}
                          alt={"Item Icon"}
                        />
                      </div>
                      <div className={styles.meetingInfoText}>
                        <div className={styles.meetingLocation}>
                          {formatTitleCase(item.title)}
                        </div>
                        <div className={styles.meetingName}>
                          {item.subTitle}
                        </div>
                        <div className={styles.meetingSpeaker}>
                          {item.speaker.name}
                        </div>
                        <div className={styles.meetingTime}>
                          {`${extractTime(item.startsOn)} (PT)`}
                        </div>
                      </div>
                    </div>
                    <div className={styles.pinIcons}>
                      <LockIcon isLocked={isLocked} />
                    </div>
                  </div>
                  {item.notes.map((note, index) => (
                    <div className={styles.alert} key={index}>
                      <Alert note={note.note} />
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        ))}
        {renderModalPin()}
        {renderModalQuestion()}
        {renderModalLocked()}
      </div>
    );
  }
);
