import { FC } from "react";
import { EventItem, Tickets, EventSessions } from "stores/interfaces.types";
import styles from "./LocalFunctionEventIcon.module.css";
import { formatDateNoYear } from "utils/formatDate";
import { getIconByTitle } from "../getIconByTitle";

interface IProps {
  events: EventItem | EventItem[] | Tickets | Tickets[] | EventSessions;
}

export const LocalFunctionEventIcon: FC<IProps> = ({ events }) => {
  const normalEvents = Array.isArray(events) ? events : [events];

  return (
    <div className={styles.section}>
      <div className={styles.photo}>
        {normalEvents.map((event, index) => {
          const iconSrc = getIconByTitle(event?.title);

          return (
            <div key={index} className={styles.icon}>
              <img src={iconSrc} alt={event?.title} />
            </div>
          );
        })}
      </div>
      <div className={styles.text}>
        {normalEvents?.map((event) => event?.title).join(" & ")}
      </div>
      {/* <div className={styles.date}>
        {normalEvents
          ?.map((event) => formatDateNoYear(event?.startsOn, event?.endsOn))
          .join(", ")}
      </div> */}
    </div>
  );
};
