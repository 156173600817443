// import { IFunction, EFunctionStatus, IFunctionIBO, IItems } from "models";
import {
  IMajorFunctions,
  ETicketStatus,
  ITempUserIBO,
  IEventItems,
} from "stores/majorInterface";

import React, { useState } from "react";
import styles from "./MajorFunctionsList.module.css";
import { MajorFunctionsDetail } from "../MajorFunctionsDetail/MajorFunctionsDetail";
import MajorFunctionHeader from "pages/MajorFunctions/MajorFunctionHeader";

interface IProps {
  selectedFunction: IMajorFunctions;
  onNext: Function;
  onPrevious: Function;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  // onClickAdd: Function;
  // onClickCancel: Function;
  // onClickPurchased: Function;
  // onClickAdd: (ibo: ITempUserIBO, event: IEventItems) => void;
  // onClickPurchased: (ibo: ITempUserIBO, event: IEventItems) => void;
  // onClickCancel: (ibo: ITempUserIBO, event: IEventItems) => void;
  onClickAdd: () => void;
  onClickPurchased: () => void;
  onClickCancel: () => void;
}

export const MajorFunctionsList: React.FC<IProps> = (props) => {
  const {
    selectedFunction,
    onNext,
    onPrevious,
    onClickAdd,
    onClickCancel,
    onClickPurchased,
    isNextDisabled,
    isPreviousDisabled,
  } = props;

  // console.log("selectedFunction", selectedFunction);

  return (
    <div className={styles.container}>
      {selectedFunction && (
        <MajorFunctionHeader
          model={selectedFunction}
          onNext={onNext}
          onPrevious={onPrevious}
          isNextDisabled={isNextDisabled}
          isPreviousDisabled={isPreviousDisabled}
        />
      )}
      {selectedFunction && (
        <MajorFunctionsDetail
          model={selectedFunction}
          onClickAdd={onClickAdd}
          onClickPurchased={onClickPurchased}
          onClickCancel={onClickCancel}
          // onClickAdd={() => {
          //   onClickAdd();
          // }}
          // onClickPurchased={() => {
          //   onClickPurchased();
          // }}
          // onClickCancel={() => {
          //   onClickCancel();
          // }}
        />
      )}
    </div>
  );
};
