import React, { useState } from "react";
import styles from "./CartItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { TicketTypeDropdown } from "components/TicketTypeDropdown/TicketTypeDropdown";

export interface IUser {
  ibo: string;
  icon: string;
  name: string;
  userId: number;
  isCredit: boolean;
  priceStr: string;
}

export interface ICartItem {
  ticketId: number;
  eventId: number;
  userId: number;
  title?: string;
  // iboName?: string;
  // iboImage: string;
  iboName?: string;
  iboImage: string;
  price?: number;
  currencyUnit?: string;
  currency: string;
  ticketType: string;
  category?: string;
  users: IUser[];
}

interface ICartItemProps {
  item: ICartItem;
  user: IUser;
  credits?: number;
  isPlatinum?: boolean;
  updatePrice?: Function;
  isCartPage: boolean;
  onDelete?: (ticketId: number, userId: number, eventId: number) => void;
}

export const CartItem = (props: ICartItemProps) => {
  const { item, user, credits, onDelete, updatePrice, isCartPage } = props;

  // const [volunteer, setVolunteer] = useState(false);
  const options = [
    { id: 1, ticketType: "New Premier Member", price: 0 },
    { id: 2, ticketType: "IBO Ticket", price: 200 },
  ];

  const initialTicketType =
    options.find((option) => option.ticketType === item.ticketType) ||
    options[1];

  const [selectedTicket, setSelectedTicket] = useState(initialTicketType);

  //TEMPORARILY COMMENTED OUT price in number
  /*const displayCurrency =
    item.currency === "CAD" ? `CAD ${item.currencyUnit}` : item.currencyUnit;

  const actualPrice =
    item.title === "Free Enterprise" ? selectedTicket.price : item.price;
  const displayedPrice = `${displayCurrency} ${Math.max(
    0,
    actualPrice - (credits || 0)
  ).toFixed(2)}`;

  const creditedPrice = `${displayCurrency ?? ""} ${Math.max(
    0,
    (item?.price ?? 0) - credits
  ).toFixed(2)}`;*/

  //Price in String
  const parsePrice = (priceStr: string): number => {
    const priceNumber = parseFloat(priceStr.replace(/[^0-9.-]+/g, ""));
    return isNaN(priceNumber) ? 0 : priceNumber;
  };

  //Convert price in number
  const formatPrice = (currency: string, price: number): string => {
    // return `${currency} ${price.toFixed(2)}`;
    return `${price.toFixed(2)}`;
  };

  const displayCurrency = item.currency === "CAD" ? "CAD" : item.currency;
  const userPrice = parsePrice(user.priceStr);
  // const displayPrice = formatPrice(
  //   displayCurrency,
  //   Math.max(0, userPrice - (credits || 0))
  // );

  const displayPrice = formatPrice(
    displayCurrency,
    Math.max(0, userPrice - (credits || 0))
  );

  // const creditedPrice = `${displayCurrency ?? ""} ${Math.max(
  //   0,
  //   (item?.price ?? 0) - credits
  // ).toFixed(2)}`;

  const creditedPrice = ` ${userPrice.toFixed(2)}`;

  // const handleDropdownChange = (event) => {
  //   setVolunteer(event.target.value === "We will volunteer = Comp Tickets");
  // };

  const handleDropdownChange = (selectedOption) => {
    setSelectedTicket(selectedOption);
    if (updatePrice) {
      updatePrice(item.ticketId, selectedOption.price);
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(item.ticketId, user.userId, item.eventId);
    }
  };

  return (
    <>
      {isCartPage ? (
        <>
          <div className={styles.container}>
            {item?.title === "Free Enterprise" && (
              <div className={styles.dropdown}>
                <TicketTypeDropdown
                  title={"Ticket Options"}
                  options={options}
                  // volunteer={true}
                  // setVolunteer={() => {}}
                  selectedOption={selectedTicket}
                  onSelect={handleDropdownChange}
                />
              </div>
            )}
            <div className={styles.ticketContainer}>
              <div className={styles.itemLeft}>
                <span>{item?.title}</span>
                &nbsp;-&nbsp;
                <div key={user.userId} className={styles.user}>
                  <span>{user.name}</span>
                </div>
                {user.isCredit ? (
                  <span className={styles.credit}>(Credit)</span>
                ) : null}
              </div>

              <div className={styles.itemRight}>
                {user.isCredit ? (
                  <div className={styles.itemPrice}>
                    <span className={styles.creditedPrice}>
                      ${creditedPrice}
                    </span>
                  </div>
                ) : (
                  <div className={styles.itemPrice}>
                    <span className={styles.price}>${displayPrice}</span>
                  </div>
                )}

                <div className={styles.itemIcon} onClick={handleDelete}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className={styles.iconClass}
                    size="sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.ticketContainer}>
              <div className={styles.itemLeft}>
                <span>{item?.title}</span>
                &nbsp;-&nbsp;
                <span>{user.name}</span>
                {user.isCredit ? (
                  <span className={styles.credit}>(Credit)</span>
                ) : null}
              </div>
              <div className={styles.itemRight}>
                {user.isCredit ? (
                  <div className={styles.itemPrice}>
                    <span className={styles.creditedPrice}>
                      ${creditedPrice}
                    </span>
                  </div>
                ) : (
                  <div className={styles.itemPrice}>
                    <span className={styles.price}>${displayPrice}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
