import React, { FC } from "react";
import LocalFunctionEventTicketItem from "../LocalFunctionEventTicketItem";
import styles from "./LocalFunctionEventsList.module.css";
import {
  EventInfo,
  EventItem,
  Tickets,
  TempUserIBO,
  EventSessions,
} from "../../../stores/interfaces.types";

interface IProps {
  // eventTickets: Tickets[];
  eventSessions: EventSessions[];
  users: TempUserIBO[];
  // eventTickets: EventInfo[];
  onClickAdd: Function;
  onClickCancel: Function;
  onClickPurchased: Function;
}

export const LocalFunctionEventsList: FC<IProps> = ({
  // eventTickets,
  eventSessions,
  users,
  onClickAdd,
  onClickCancel,
  onClickPurchased,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <p>Event</p>
        <p>Tickets</p>
      </div>
      {/* {eventTickets.map((item, index) => ( */}
      {eventSessions.map((item, index) => {
        return (
          <div key={index} className={styles.eventItem}>
            <LocalFunctionEventTicketItem
              // event={item}
              session={item}
              users={users}
              onClickAdd={(user) => onClickAdd(item, user)}
              // onClickAdd={onClickAdd}
              onClickPurchased={(user) => onClickPurchased(item, user)}
              onClickCancel={(user) => onClickCancel(item, user)}
            />
          </div>
          // ))}
        );
      })}
    </div>
  );
};
