import React, { useState, useEffect } from "react";
import SessionItem from "components/SessionItem";
import { FC } from "react";
import styles from "./LocalFunctionInfoCard.module.css";
// import { IFunctionInfoCard } from "./FunctionInfoCard.types";
// interface IProps extends IFunctionInfoCard {}

interface IProps {
  // sessions: any;
  speakerInfo: { firstName: string; lastName: string; image: string } | null;
  sessionInfo: {
    title: string;
    startTime: string;
    endTime: string;
    description: string;
  } | null;
  // | Array<{
  //     title: string;
  //     startTime: string | null;
  //     endTime: string | null;
  //     description: string;
  //   }>;
  priceStr: string;
}

export const LocalFunctionInfoCard: React.FC<IProps> = (props) => {
  const { sessionInfo, speakerInfo, priceStr } = props;

  const { image, firstName, lastName } = speakerInfo || {};
  // const { fullName,startTime, endTime, note } = eventInfo || {};

  const [isMobile, setIsMobile] = useState(window.innerWidth < 425);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 425);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderInfoLeftMobile = () => {
    return (
      <div className={styles.mobileContainer}>
        <div className={styles.leftMobile}>
          <div className={styles.photoMobile}>
            <img src={image} alt="Speaker" />
          </div>
        </div>

        <div className={styles.namesContainer}>
          <div>
            <p className={styles.namesMobile}>
              {/* {name} */}
              <span>{firstName} </span>
              <span className={styles.lastNameMobile}> {lastName}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderInfoRightMobile = () => {
    return (
      <div className={styles.right}>
        <div className={styles.sessionContent}>
          {Array.isArray(sessionInfo) ? (
            sessionInfo.map((event, index) => (
              <div key={index} className={styles.sessionItem}>
                <SessionItem
                  name={event?.title}
                  startTime={event?.startTime}
                  endTime={event?.endTime}
                  note={event?.description}
                />
              </div>
            ))
          ) : (
            <div className={styles.sessionItem}>
              <SessionItem
                name={sessionInfo?.title}
                startTime={sessionInfo?.startTime}
                endTime={sessionInfo?.endTime}
                note={sessionInfo?.description}
              />
            </div>
          )}
        </div>
        <div className={styles.priceMobile}>{priceStr}</div>
      </div>
    );
  };

  const renderInfoLeft = () => {
    return (
      <div className={styles.itemLeft}>
        <div className={styles.photo}>
          <img src={image} alt="" />
        </div>
      </div>
    );
  };

  const renderInfoRight = () => {
    return (
      <div className={styles.itemRight}>
        <div className={styles.names}>
          {/* <span>{name}</span> */}
          <span>{firstName}</span>
          <span className={styles.lastName}>{lastName}</span>
        </div>

        <div className={styles.sessionContent}>
          {Array.isArray(sessionInfo) ? (
            sessionInfo.map((event, index) => (
              <div key={index} className={styles.sessionItem}>
                <SessionItem
                  name={event?.title}
                  startTime={event?.startTime}
                  endTime={event?.endTime}
                  note={event?.description}
                />
              </div>
            ))
          ) : (
            <div className={styles.sessionItem}>
              <SessionItem
                name={sessionInfo?.title}
                startTime={sessionInfo?.endTime || ""}
                endTime={sessionInfo?.endTime || ""}
                note={sessionInfo?.description}
              />
            </div>
          )}
        </div>
        <div className={styles.price}>{priceStr}</div>
      </div>
    );
  };

  const eventFullName = Array.isArray(sessionInfo)
    ? sessionInfo[0]?.title
    : sessionInfo?.title;

  return (
    <div
      className={
        eventFullName === "Organizational"
          ? styles.orgContainer
          : styles.container
      }
    >
      <div className={styles.itemLeft}>
        {isMobile ? renderInfoLeftMobile() : renderInfoLeft()}
      </div>
      <div>{isMobile ? renderInfoRightMobile() : renderInfoRight()}</div>
    </div>
  );
};
