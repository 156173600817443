import { FC } from "react";
// import { IEvent } from "pages/HomeSite/HomeSite.types";
import { EventItem } from "stores/homesiteInterface";
import styles from "./EventIcon.module.css";
import { formatDateNoYear } from "utils/formatDate";
import SLMIcon from "assets/icons/Second_Look_Icon.svg";
import SeminarIcon from "assets/icons/Seminar_Icon.svg";
import VirtualIcon from "assets/icons/Virtual_Icon.svg";
import OrganizationalIcon from "assets/icons/Organizational_icon.svg";
import RegionalIcon from "assets/icons/Regional_Icon.svg";

interface IProps {
  events: EventItem | EventItem[];
}

export const EventIcon: FC<IProps> = ({ events }) => {
  const normalEvents = Array.isArray(events) ? events : [events];

  const getIconByTitle = (title) => {
    if (title === "Seminar") {
      return SeminarIcon;
    } else if (title === "Second Look") {
      return SLMIcon;
    } else if (title === "Virtual" || title.includes("Virtual")) {
      return VirtualIcon;
    } else if (title === "Organizational") {
      return OrganizationalIcon;
    } else if (title === "Regional") {
      return RegionalIcon;
    }
    return null;
  };

  return (
    <div className={styles.section}>
      <div className={styles.photo}>
        {normalEvents.map((event, index) => {
          const iconSrc = getIconByTitle(event?.title);

          return (
            <div key={index} className={styles.icon}>
              <img src={iconSrc} alt={event?.title} />
            </div>
          );
        })}
      </div>
      <div className={styles.text}>
        {normalEvents?.map((event) => event?.title).join(" & ")}
      </div>
      <div className={styles.date}>
        {normalEvents
          ?.map((event) => formatDateNoYear(event?.startsOn, event?.endsOn))
          .join(", ")}
      </div>
    </div>
  );
};
