import React from "react";
import { FC } from "react";
import EventIcon from "components/EventIcon";
import TicketPurchaseItem from "components/TicketPurchaseItem";
import styles from "./EventTicketItem.module.css";
import { EventInfo, EventItem } from "stores/homesiteInterface";

interface IProps {
  // event: EventInfo;
  event: EventItem;

  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const EventTicketItem: FC<IProps> = ({
  event,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  // const currentEvent = event?.event[0];
  return (
    <div className={styles.section}>
      <div className={styles.left}>
        <EventIcon events={event} />
      </div>
      <div className={styles.right}>
        {event?.users?.map((user, index) => (
          <TicketPurchaseItem
            key={index}
            user={user}
            onClickAdd={() => onClickAdd(user)}
            onClickPurchased={() => onClickPurchased(user)}
            onClickCancel={() => onClickCancel(user)}
          />
        ))}
      </div>
    </div>
  );
};
