import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useMutation, useQueryClient } from "react-query";
import env from "../../environment";

interface DeleteCartItemParams {
  eventId: number;
  ticketId: number;
  userId: number;
}

export const useDeleteCartItem = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ eventId, ticketId, userId }: DeleteCartItemParams) => {
      const res = await makeAuthedRequest(
        "cart/delete",
        {
          method: "DELETE",
          body: JSON.stringify({ eventId, ticketId, userId }),
        },
        env.apiBase
      );
      if (res.ok) {
        return res;
      } else {
        const body = await res.json();
        console.error("Error deleting cart item:", body.error);
        throw new Error(body.error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cartData");
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );
};
