import React, { useEffect, useState } from "react";
import { Selector, TextInput } from "@wwg/ui";
import styles from "./LocalFunctions.module.css";
import UpcomingMeetingsList from "components/UpcomingMeetingsList";
import { useHistory } from "react-router-dom";
import * as Utils from "../../utils";
import Popup from "../../components/Popup";
import InputRange from "components/InputRange";
import SearchIcon from "components/SearchIcon";

import { EventItem, LocalFunctionsData } from "stores/interfaces.types";
import { useLocalFunctions } from "./useLocalFunctions";
import { formatDate } from "utils/formatDate";

import FilterIcon from "./icons/Filter_icon.svg";
import InfoIcon from "./icons/Info_Icon.svg";
import LocationArrowIcon from "./icons/Location_Arrow_Icon.svg";
import ArrowIcon from "./icons/Arrow.svg";
import Loader from "components/Loader";
import { Select } from "components/Select/Select";
import { stateOptions } from "./statesData";

interface LocalFunctionsProps {}

export const LocalFunctions = React.memo(() => {
  const [selectedState, setSelectedState] = useState("WA");
  const { data, isLoading, refetch } = useLocalFunctions(selectedState);

  const localFunctionsData = data as LocalFunctionsData | undefined;

  const history = useHistory();
  const [showPopupFilter, setShowPopupFilter] = useState(false);
  const [showPopupSearch, setShowPopupSearch] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (selectedState) {
      refetch();
    }
  }, [selectedState, refetch]);

  useEffect(() => {
    if (localFunctionsData) {
      setFilteredItems(localFunctionsData.items);
    }
  }, [localFunctionsData]);

  const handleMeetingSelect = (eventId) => {
    if (!localFunctionsData || !localFunctionsData.items) {
      return;
    }

    // Find the event with the specified eventId
    const selectedEvent = localFunctionsData.items.find(
      (item) => item.eventId === eventId
    );

    if (!selectedEvent) {
      return;
    }

    setSelectedEvent(selectedEvent);

    const path = Utils.PATHS.LOCAL_FUNCTION_MEETING.replace(
      ":eventId",
      eventId
    );

    history.push(path, { items: localFunctionsData.items });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const formattedStateOptions = stateOptions.map((option) => ({
    display: `${option.abbreviation} - ${option.state}`,
    value: option.abbreviation,
  }));

  //Triggers filtering of result
  const handleFilterSearch = (query) => {
    if (localFunctionsData) {
      const term = query.toLowerCase();

      const filtered = localFunctionsData.items.filter((item) => {
        return (
          item.title.toLowerCase().includes(term) ||
          item.subTitle.toLowerCase().includes(term) ||
          item.speaker.name.toLowerCase().includes(term) ||
          formatDate(item.startsOn).toLowerCase().includes(term) ||
          item.location.line1.toLowerCase().includes(term) ||
          item.location.city.toLowerCase().includes(term) ||
          item.location.zip.toLowerCase().includes(term)
        );
      });

      setFilteredItems(filtered);
    }
  };

  //TODO: No functionality yet
  const PopupFilter = () => {
    return (
      <Popup
        isOpen={showPopupFilter}
        isCloseIcon={false}
        onClose={() => setShowPopupFilter(false)}
      >
        <div className={styles.filter}>
          <div>
            <div className={styles.filterCustomize}>Customize Location</div>
            <button className={styles.filterButton}>
              <img src={LocationArrowIcon} alt="Location Arrow" />
              <div className={styles.useText}>Use My Current Location</div>
            </button>
            <div className={styles.filterDistance}>
              Show me listings within a specific distance.
            </div>
            <InputRange className={styles.filterRange} max={500} />
          </div>
          <div className={styles.filterFooter}>
            <button
              className={styles.filterButtonCancel}
              onClick={() => setShowPopupFilter(false)}
            >
              Cancel
            </button>
            <button className={styles.filterButtonApply}>Apply</button>
          </div>
        </div>
      </Popup>
    );
  };

  const PopupSearch = () => {
    const handleInputChange = (event) => {
      const query = event.target.value;
      setSearchTerm(query);
      handleFilterSearch(query);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleFilterSearch(searchTerm);
        setShowPopupSearch(false);
      }
    };

    return (
      <Popup
        isOpen={showPopupSearch}
        isCloseIcon={false}
        className={styles.search}
        onClose={() => setShowPopupSearch(false)}
      >
        <div className={styles.searchContent}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <div
            className={styles.searchCancel}
            onClick={() => setShowPopupSearch(false)}
          >
            Cancel
          </div>
        </div>
      </Popup>
    );
  };

  if (isLoading) {
    return (
      <div>
        <Loader color={"#415364"} size={85} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {showPopupFilter && PopupFilter()}
      {showPopupSearch && PopupSearch()}
      <div className={styles.header}>
        <Select
          options={formattedStateOptions}
          onChange={handleStateChange}
          placeholder={
            formattedStateOptions.find(
              (option) => option.value === selectedState
            )?.display || "Select State"
          }
          dropdownRight={false}
          isScrollable={true}
          isStateSelect={true}
          className={styles.select}
        />
        <div
          className={styles.headerSVG}
          onClick={() => setShowPopupSearch(true)}
        >
          <SearchIcon />
        </div>
        <div
          className={styles.headerFilter}
          onClick={() => setShowPopupFilter(true)}
        >
          <img src={FilterIcon} alt="Filter" />
        </div>
      </div>
      <div
        className={styles.headerInformation}
        onClick={() => history.push(Utils.PATHS.LOCAL_FUNCTION_INFO)}
      >
        <div className={styles.headerInformationLeft}>
          <img src={InfoIcon} alt="Info" />
          <span className={styles.headerInfoText}>
            {localFunctionsData?.primaryButtonText}
          </span>
        </div>
        <img src={ArrowIcon} alt="Arrow" />
      </div>
      <div className={styles.headerUpcoming}>
        {localFunctionsData &&
        localFunctionsData?.items?.length > 0 &&
        filteredItems.length > 0
          ? localFunctionsData.headerText || "Upcoming Meetings"
          : "No Upcoming Meetings"}
      </div>
      <div className={styles.content}>
        <UpcomingMeetingsList
          items={filteredItems}
          icon={localFunctionsData.icon}
          selectedMeetingItem={handleMeetingSelect}
        />
      </div>
    </div>
  );
});
