import React, { FC } from "react";
import styles from "./TicketPurchaseItem.module.css";
import Add_Button from "assets/icons/Add_Button.svg";
import Close_button from "assets/icons/Close_button.svg";
import Ticket_Added from "assets/icons/Ticket_Added.svg";
import Purchased_Icon from "assets/icons/Purchased_Icon.svg";
import classNames from "classnames";
import { TicketStatus, UserIBO } from "stores/homesiteInterface";

interface IProps {
  user: UserIBO;
  onClickAdd: Function;
  onClickPurchased: Function;
  onClickCancel: Function;
}

export const TicketPurchaseItem: FC<IProps> = ({
  user,
  onClickAdd,
  onClickPurchased,
  onClickCancel,
}) => {
  const ticketStatus = user.tickets[0]?.status;

  return (
    <div className={styles.container}>
      <div className={styles.ticket}>
        <img
          className={classNames(styles.image, {
            [styles.opacity]: [
              TicketStatus.Selected,
              TicketStatus.Purchased,
              TicketStatus.Included,
            ].includes(ticketStatus),
          })}
          src={user.src}
          alt={"Ibo User"}
        />
        {ticketStatus === TicketStatus.Selected ? (
          <>
            <div className={styles.buttonIBO}>
              <img src={Ticket_Added} alt="Ticket Added" />
            </div>
            <div
              className={styles.closeButton}
              onClick={() => onClickCancel(user)}
            >
              <img src={Close_button} alt="Close" />
            </div>
          </>
        ) : ticketStatus === TicketStatus.Purchased ? (
          <div
            className={styles.buttonPurchased}
            onClick={() => onClickPurchased(user)}
          >
            <img src={Purchased_Icon} alt="Purchased" />
          </div>
        ) : (
          ticketStatus !== TicketStatus.Included && (
            <div className={styles.buttonAdd} onClick={() => onClickAdd(user)}>
              <img src={Add_Button} alt="Add" />
            </div>
          )
        )}
      </div>
      {/* <div className={styles.iboName}>{user.firstName}</div> */}
      <div className={styles.iboName}>{user.Name}</div>

      {ticketStatus === TicketStatus.Included && (
        <div className={styles.textIncluded}>
          <p>Included with in-person ticket!</p>
        </div>
      )}
    </div>
  );
};
