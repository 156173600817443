import { useQuery, useQueryClient, useMutation } from "react-query";
import { makeAuthedRequest } from "utils/makeAuthedRequest";
import env from "../environment";

export function useUser() {
  const { data, isLoading, refetch } = useQuery(
    ["user"],
    () =>
      makeAuthedRequest("/user", { method: "GET" }, env.apiBase).then(
        async (res) => {
          const json = await res.json();
          if (res.ok) {
            return json;
          } else {
            throw new Error(json.message);
          }
        }
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    data,
    isLoading,
    refetch,
  };
}
