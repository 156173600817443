// //CartContext

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useCartInfo } from "pages/CartPage/useCartInfo";
import { useUser } from "./useUser";

interface ICartItem {
  ticketId: number;
  eventId: number;
  title: string;
  // ticketName?: string;
  iboName?: string;
  iboImage: string;
  price?: number;
  currencyUnit?: string;
  currency: string;
  // eventName?: string;
  location?: string;
  eventDate?: string;
  ticketType: string;
}

interface CartContextType {
  cartItems: ICartItem[];
  //   addToCart: (item: Omit<ICartItem, "uniqueId">) => void;
  addToCart: (item) => void;

  removeFromCart: (ticketId: number) => void;
  updateCartItem: (ticketId: number, item: Partial<ICartItem>) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC = ({ children }) => {
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);

  const { data: itemsData = [] } = useCartInfo();
  const { data: userData = [] } = useUser();

  // console.log("USER:", userData);

  //ADD TO CART
  const addToCart = useCallback((item) => {
    // const allEvents = fakeCartData();
    // console.log("CART ITEM", item);

    // Verify itemsData structure
    // if (!itemsData || itemsData.length === 0) {
    //   console.error("Items data is not loaded or empty");
    //   return;
    // }

    // console.log("CART ITEM:", item);

    const eventTickets = itemsData?.find((ticketItem) => {
      return ticketItem.items?.some((e) => e.ticketId === item.ticketId);
    });

    // console.log("CART eventTickets:", eventTickets);

    if (eventTickets) {
      const eventDetails = eventTickets.items?.find(
        (e) => e.ticketId === item.ticketId
      );

      if (eventDetails) {
        setCartItems((prevItems) => [
          ...prevItems,
          {
            ...item,
            // eventName: eventTickets.eventName,
            // location: eventDetails.location,
            // eventDate: eventTickets.eventDate,
            // category: eventTickets.category,
          },
        ]);
        //   } else {
        //     console.error("No event details found for eventId:", item.ticketId);
        //   }
        // } else {
        //   console.error("No ticket found for eventId:", item.ticketId);
      }
    }
  }, []);

  const removeFromCart = useCallback((ticketId) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.filter(
        (item) => item.ticketId !== ticketId
      );
      return updatedItems;
    });
  }, []);

  const updateCartItem = useCallback((ticketId, newPrice) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.ticketId === ticketId ? { ...item, price: newPrice } : item
      )
    );
  }, []);

  return (
    <CartContext.Provider
      value={{ cartItems, addToCart, removeFromCart, updateCartItem }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
