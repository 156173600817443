import { makeAuthedRequest } from "utils/makeAuthedRequest";
import { useQuery, useMutation, useQueryClient } from "react-query";
import env from "../../environment";

export function useCartInfo() {
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(
    "cartData",
    () =>
      makeAuthedRequest(
        "cart",
        {
          method: "GET",
        },
        env.apiBase
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error((await res.json()).message);
        }
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  return {
    data,
    isLoading,
    refetch,
    // deleteCartItem,
  };
}
